import { locale } from './i18n';
import { computed, watch, ref } from 'vue';

const analyticsUser = ref(null);

window.fetch = (
    fetch =>
    async (...args) => {
        const response = await fetch(...args);
        if (
            new URL(response.url).origin === location.origin &&
            response.headers.get('X-Analytics-User')
        ) {
            analyticsUser.value = response.headers.get('X-Analytics-User');
        }
        return response;
    }
)(window.fetch);

const user = computed(() => {
    return { userID: analyticsUser.value, locale: locale.value };
});

import('./statsigClient.js').then(
    async ({
        StatsigClient,
        StatsigSessionReplayPlugin,
        StatsigAutoCapturePlugin,
    }) => {
        const options = {
            plugins: [
                new StatsigSessionReplayPlugin(),
                new StatsigAutoCapturePlugin(),
            ],
            environment: { tier: process.env.VUE_APP_SENTRY_ENVIRONMENT },
        };

        const myStatsigClient = new StatsigClient(
            'client-OenAazYop0FMgHmt6nlZVrBUU9lzDD89s3lSS9g5Nf6',
            user.value,
            options
        );

        await myStatsigClient.initializeAsync();

        watch(user, u => {
            console.log('Updating Statsig user', u);
            myStatsigClient.updateUserAsync(u);
        });
    }
);

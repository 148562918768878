import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { SessionExpiredError } from './unauthorizedRedirect';

if (
    process.env.VUE_APP_SENTRY_ENVIRONMENT === 'production' &&
    !isSensitiveUrl()
) {
    Sentry.init({
        Vue,
        dsn: 'https://efce6564ec7147d6ae65acfd5c985161@o912543.ingest.sentry.io/5855880',
        integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 0.05,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
        trackComponents: true,
        logErrors: true,
        tunnel: process.env.VUE_APP_API_URL + '/sentry/',
        replaySessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, hint) {
            if (
                isApplicationError(hint) ||
                hint.originalException instanceof SessionExpiredError ||
                (isRouterError(hint) && isRedirectToLoginPage(hint))
            ) {
                return null;
            }
            return event;
        },
    });
}

function isSensitiveUrl() {
    return /\/sign_document\//.test(window.location);
}

function isRedirectToLoginPage(hint) {
    return ['userLogin', 'employeeLogin'].includes(
        hint.originalException.to.name
    );
}

function isRouterError(hint) {
    return hint.originalException?._isRouter;
}

function isApplicationError(hint) {
    return hint.originalException?.graphQLErrors?.some(
        error => error.extensions?.is_application_error
    );
}
